import React from "react";
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const CoachingPage = () => (
  <Layout>
    <Seo
      title="Coaching | Verhaltenstherapie München | Praxis für Psychotherapie"
      description="Psychologische Beratung & Coaching in Phasen von Veränderung, lebensrelevanten Fragestellungen, Stressmanagement & Entwicklung der Persönlichkeit."
    />

    <div className="relative">
      <div className="w-full h-96">
        <StaticImage
          className="w-full h-full"
          src="../assets/images/hero-ueber-mich.jpg"
          alt=""
        />
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="lg:text-4xl">Coaching</h1>
            {/* <div>Diese Seite befindet sich im derzeit im Aufbau. Bei Fragen wenden Sie sich gerne an mich</div> */}
          </div>
        </div>
      </div>
    </div>

    <div className="my-12 boxed max-w-prose generic-page">
      <p>
        Sie sind selbstverständlich auch herzlich in meiner Praxis willkommen,
        wenn keine psychische Erkrankung vorliegt. Beim Coaching geht es um
        Weiterentwicklung, Nutzung vorhandener und Erschließung neuer Ressourcen
        und Kompetenzen.
      </p>
      <p>
        Häufige Anliegen sind die Weiterentwicklung der Persönlichkeit im
        privaten oder beruflichen Kontext, die Verbesserung sozialer Kompetenzen
        (Konfliktfähigkeit, auf andere Menschen zugehen, Beziehungsfähigkeit,
        Einfordern von dem, was einem zusteht), das Erlernen von effektivem
        Stressmanagement, der Umgang mit Mobbing, die Steigerung des
        Selbstwertes oder auch die Verbesserung der Selbstfürsorge. Gemeinsam
        entwickeln wir eine passende Strategie, wie Sie Ihre Ziele erreichen
        können.
      </p>
      <p>
        Die Sitzungsanzahl richtet sich individuell nach Ihren Bedürfnissen und
        dem Umfang Ihres Anliegens, frei nach dem Motto so viel wie nötig, so
        wenig wie möglich.
      </p>
    </div>
  </Layout>
);

export default CoachingPage;
